@use "../variables";

.u-margin-reset {
  margin: 0;
}

.u-margin-5 {
  margin: variables.$margin-5;
}

.u-margin-bottom-8 {
  margin-bottom: variables.$margin-bottom-8;
}

.u-margin-bottom-10 {
  margin-bottom: variables.$margin-bottom-10;
}

.u-margin-bottom-20 {
  margin-bottom: variables.$margin-bottom-20!important;
}

.u-margin-bottom-30 {
  margin-bottom: variables.$margin-bottom-30;
}

.u-margin-top-20 {
  margin-top: variables.$margin-top-20!important;
}

.u-margin-top-30 {
  margin-top: variables.$margin-top-30;
}

.u-margin-top-10 {
  margin-top: variables.$margin-top-10!important;
}

.u-margin-bottom-50 {
  margin-bottom: variables.$margin-bottom-50;
}

.u-margin-top-50 {
  margin-top: variables.$margin-top-50;
}

.u-margin-right-50 {
  margin-right: variables.$margin-right-50;
}

.u-margin-right-20 {
  margin-right: variables.$margin-right-20;
}

.u-margin-right-10 {
  margin-right: variables.$margin-right-10;
}

.u-margin-right-5 {
  margin-right: variables.$margin-right-5;
}

.u-margin-left-10 {
  margin-left: variables.$margin-left-10;
}
