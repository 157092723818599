/*------------------------------------*\
  #Line clamp
\*------------------------------------*/
$line-clamps: 2 3;

@each $line-clamp in $line-clamps {
  .u-line-clamp-#{$line-clamp} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line-clamp;
  }
}

.u-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
