/*------------------------------------*\
  #Bokeh chart styles
\*------------------------------------*/

.bk-toolbar-box {

  @media print {
    display: none;

  }

}
