.u-display-inline-block {
  display: inline-block;
}

.u-display-block {
  display: block!important;
}

.u-display-none {
  display: none;
}
