.u-ellipsis-general {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.u-ellipsis-1-line {
  -webkit-line-clamp: 1;
}

.u-ellipsis-5-line {
  -webkit-line-clamp: 5;
}
