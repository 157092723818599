/*------------------------------------*\
  #Custom max, min and regular widths
\*------------------------------------*/
@use "sass:math";

.width-33-percent {
  width: 33%;
}

.width-32-percent {
  width: 31%;
}

.width-49-percent {
  width: 49%;
}

.width-100-percent {
  width: 100% !important;
}

.inherit-width {
  width: inherit;
}

/**
 * Fix widths
 */
$widths: 20 40 140 260 350;

@each $width in $widths {
  .u-width-#{$width} {
    width: $width + px !important;
  }
}


/*------------------------------------*\
  #Widths - responsive use
\*------------------------------------*/
$fractions: 1 2 3 4 5 !default;
$offsets: false !default;
$widths-delimiter: \/ !default;
$widths-breakpoint-separator: \@ !default;

@mixin widths($columns, $breakpoint: null) {

  // Loop through the number of columns for each denominator of our fractions.
  @each $denominator in $columns {

    // Begin creating a numerator for our fraction up until we hit the
    // denominator.
    @for $numerator from 1 through $denominator {

      // Build a class in the format `.u-3/4[@<breakpoint>]`.

      .u-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
        width: math.div($numerator, $denominator) * 100% !important;
      }

      @if ($offsets == true) {

        /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */

        // Build a class in the format `.u-push-1/2[@<breakpoint>]`.

        .u-push-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
          position: relative !important;
          right: auto !important; /* [1] */
          left: math.div($numerator, $denominator) * 100% !important;
        }

        // Build a class in the format `.u-pull-5/6[@<breakpoint>]`.

        .u-pull-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
          position: relative !important;
          right: math.div($numerator, $denominator) * 100% !important;
          left: auto !important; /* [1] */
        }

      }

    }

  }

  @if ($offsets == true and $breakpoint != null) {

    // Create auto push and pull classes.

    .u-push-none#{$breakpoint} {
      left: auto !important;
    }

    .u-pull-none#{$breakpoint} {
      right: auto !important;
    }

  }

}

@include widths($fractions);

@if (variable-exists(mq-breakpoints)) {

  @each $bp-name, $bp-value in $mq-breakpoints {

    @include mq($from: $bp-name) {
      @include widths($fractions, #{$widths-breakpoint-separator}#{$bp-name});
    }

  }

}
