/*------------------------------------*\
  #Buttons
\*------------------------------------*/
@use "variables";

.c-btn-blue {
  border: none;
  background-color: variables.$blue;
  color: white;
  border-radius: variables.$border-radius-xl;
  padding: variables.$global-size-m;
  width: inherit;
  cursor: pointer;
  font-size: variables.$global-size-m;
  font-weight: bold;
  transition: background-color 0.24s ease-in;

  &:hover {
    background-color: rgba(variables.$blue, 0.9);
  }

  &:disabled {
    background-color: rgba(variables.$dark-gray-color, 0.6);
  }
}

.c-btn-green {
  border: none;
  background-color: variables.$green!important;
  color: white;
  border-radius: variables.$border-radius-xl;
  padding: variables.$global-size-m;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.24s ease-in;

  &:hover {
    background-color: rgba(variables.$light-green, 0.9)!important;
  }

  &:disabled {
    background-color: rgba(variables.$gray-border-color, 1)!important;
  }
}

.c-btn-red {
  border: none;
  background-color: variables.$red-color!important;
  color: white;
  border-radius: variables.$border-radius-xl;
  padding: variables.$global-size-m;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.24s ease-in;

  &:hover {
    background-color: rgba(variables.$red, 0.9)!important;
  }

  &:disabled {
    background-color: rgba(variables.$gray-border-color, 1)!important;
  }
}

.c-btn-warn {
  border: none;
  background-color: variables.$red-color!important;
  color: white;
  border-radius: variables.$border-radius-xl;
  padding: variables.$global-size-m;
  width: inherit;
  cursor: pointer;
  font-size: variables.$global-size-m;
  font-weight: bold;
  transition: background-color 0.24s ease-in;

  &:hover {
    background-color: rgba(variables.$red, 0.9)!important;
  }

  &:disabled {
    background-color: rgba(variables.$gray-border-color, 1)!important;
  }
}
