/*------------------------------------*\
  #Font weights
\*------------------------------------*/

.u-font-weight-100 {
  font-weight: 100;
}

.u-font-weight-normal {
  font-weight: 400;
}

.u-font-weight-500 {
  font-weight: 500;
}

.u-font-weight-400 {
  font-weight: 400;
}


.u-font-weight-700 {
  font-weight: 700;
}

.u-font-weight-bold {
  font-weight: bold;
}
