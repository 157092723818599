@mixin color-palette($theme) {
	$success-color: map-get($theme, inscyd, success);
	$warning-color: map-get($theme, inscyd, warning);
	$error-color: map-get($theme, inscyd, error);
	$text-color: map-get($theme, inscyd, text);
	$subtitle-color: map-get($theme, inscyd, subtitle);
	$border-color: map-get($theme, inscyd, border);
	$lighter-bg-color: map-get($theme, inscyd, lighter-bg);
	$light-bg-color: map-get($theme, inscyd, light-bg);
	$dark-bg-color: map-get($theme, inscyd, dark-bg);
	$darker-bg-color: map-get($theme, inscyd, darker-bg);
	$shadow-color: map-get($theme, inscyd, shadow);
	$primary-color: map-get($theme, inscyd, primary);
	$primary-icon: map-get($theme, inscyd, icon);
}

@mixin inscyd($theme) {
	@include color-palette($theme);
	$success-color: map-get($theme, inscyd, success);
	$warning-color: map-get($theme, inscyd, warning);
	$error-color: map-get($theme, inscyd, error);
	$text-color: map-get($theme, inscyd, text);
  $snack-bar-text-color: map-get($theme, inscyd, snack-bar);
  $grid-break-divider: map-get($theme, inscyd, grid-break-divider);
	$subtitle-color: map-get($theme, inscyd, subtitle);
	$border-color: map-get($theme, inscyd, border);
	$lighter-bg-color: map-get($theme, inscyd, lighter-bg);
	$light-bg-color: map-get($theme, inscyd, light-bg);
	$dark-bg-color: map-get($theme, inscyd, dark-bg);
	$darker-bg-color: map-get($theme, inscyd, darker-bg);
	$shadow-color: map-get($theme, inscyd, shadow);
	$primary-color: map-get($theme, inscyd, primary);
	$primary-icon: map-get($theme, inscyd, icon);
	$tile-background: map-get($theme, inscyd, tile-background);
	$default-color: map-get($theme, inscyd, default-color);
	$second-color: map-get($theme, inscyd, second-color);
	$color-background: map-get($theme, inscyd, color-background);
	$color-background-intense: map-get($theme, inscyd, color-background-intense);
  $dropdown-background-color: map-get($theme, inscyd, dropdown-background-color);
	$disabled-background: map-get($theme, inscyd, disabled-background);
	$disabled-text: map-get($theme, inscyd, disabled-text);
  $highlighted-table-row: map-get($theme, inscyd, highlighted-table-row);
  $faded-text-color: map-get($theme, inscyd, faded-text-color);

  .text-color {
    color: $text-color;
  }

  .primary-text-color {
    color: $primary-color;
  }

  .disable-background {
    background-color: $disabled-background;
  }

  .highlighted-table-row {
    background-color: $highlighted-table-row;
  }

  .disabled-text {
    color: $disabled-text;
  }

  .tile {
    background: $tile-background;
    color: $default-color;
  }

  .feature-detail-second-color {
    color: $second-color;
  }

  .background-color {
    background-color: $color-background;
  }

  .background-color-intense {
	background-color: $color-background-intense;
  }

  .dropdown-background-color {
    background-color: $dropdown-background-color;
  }

  .border-color {
    border: 1px solid $border-color;
  }

  .faded-text-color {
    color: $faded-text-color;
  }

  .feature-detail-color {
    color: $default-color;
  }

  .report-grid__break {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 2px dashed $grid-break-divider;
  }

	.section {
		@include section($darker-bg-color, $shadow-color, $border-color, $subtitle-color);
	}

	.mat-slide-toggle {
		&.mat-checked {
			.mat-slide-toggle-thumb {
				background-color: white !important;
			}

			.mat-slide-toggle-bar {
				background-color: $primary-color !important;
			}
		}
	}

	body {
		color: $text-color;
	}

	.general-dialog mat-dialog-container {
		background-color: $darker-bg-color;
	}

	.app-content {
		height: 100%;
	}

	.createNew {
		.header__relative {
			position: relative;

			.header__fixed {
				position: fixed;
				top: 9rem;
			}
		}

		.mat-tab-body-content {
			overflow-x: hidden;
		}

		.mat-expansion-panel-header {
			border: 1px solid $border-color;
			padding: 0 0.6rem;
		}

		.mat-expansion-indicator {
			padding-right: 1.5rem;
		}

		.mat-expansion-panel-body {
			padding: 0px !important;
		}

		.mat-expansion-panel:not([class*='mat-elevation-z']) {
			box-shadow: none;
		}

		.form-group {
			.mat-form-field {
				width: 100%;
				background-color: $darker-bg-color;
			}

			mat-form-field.mat-chip-select {
				.mat-form-field-flex {
					padding-top: 0px;
				}
			}

			.multiple-select {
				.mat-form-field-flex {
					background-color: $darker-bg-color;
					border: 1px solid $border-color;
					border-radius: 5px;
				}

				.mat-form-field-underline {
					display: none;
				}
			}

			.event-Description {
				height: 10rem;

				.mat-form-field-flex {
					height: 8.5rem;
				}
			}
		}

		.box-header-popup {
			padding: 10px 20px;
		}
	}

	.mat-drawer {
		background: $darker-bg-color;

		.mat-expansion-panel {
			background: $darker-bg-color;
		}
	}

	snack-bar-container {
		.mat-simple-snackbar {
			color: $snack-bar-text-color;
		}
		.mat-simple-snackbar-action {
			color: $snack-bar-text-color;
		}

		&.success {
			background: $success-color;
		}

		&.warning {
			background: $warning-color;
		}

		&.error {
			background: $error-color;
		}
	}

	mat-sidenav-container {
		height: calc(100% - 60px);

		mat-sidenav {
			width: 300px;
			border-right: 1px solid $border-color;
		}

		.side-org-user-container {
			height: 120px;
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			display: flex;

			#current_login_logo {
				height: 64px;
				width: 64px;
				background-size: cover;
				border: 1px solid $border-color;
				object-fit: contain;
			}

			.org-logo-container {
				height: 80px;
				width: 80px;
				border: 1px solid $border-color;
				box-sizing: border-box;
				display: flex;
				align-content: center;
				align-items: center;
				justify-content: space-around;

				mat-icon {
					transform: scale(2);
				}
			}

			.side-org-user-name-container {
				height: 80px;
				padding-left: 10px;
				width: 180px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				box-sizing: border-box;
				justify-content: center;

				.side-user-name {
					font-weight: bold;
				}

				.side-org-name {
					color: $subtitle-color;
				}
			}
		}

		.org-slogan {
			width: 100%;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			font-size: 1.2em;
		}

		mat-accordion.left-menu {
			border-top: 1px solid $border-color;

			mat-expansion-panel {
				cursor: pointer;
				box-shadow: none !important;
				border-left: 3px solid transparent;
				color: $text-color;

				mat-icon {
					margin-right: 15px;
				}

				span {
					&[class^='extra-inscyd-icon-moon-'],
					&[class^='iatac-icon-'] {
						font-size: 24px;
						margin-right: 15px;
					}

					&.iatac-icon-training-zone {
						margin-right: 17px;
						margin-left: 2px;
					}

					&.iatac-icon-recover-matrix,
					&.iatac-icon-manage {
						font-size: 20px;
					}
				}

				.mat-content {
					align-items: center;
				}

				a.external-link {
					display: flex;
					text-decoration: none;
					color: $text-color;
				}
			}

			.mat-expansion-panel-header[aria-disabled='true'] {
				color: $text-color;
			}

			mat-expansion-panel:hover,
			mat-expansion-panel.active {
				box-shadow: none;
				// color: $primary-color;
				border-left: 3px solid $primary-color;
				box-sizing: border-box;

				.mat-expansion-panel-header {
					color: $primary-color;
				}

				a.external-link,
				a.active {
					color: $primary-color;
				}

        .custom-svg-icon-color {
          filter: brightness(0) saturate(100%) invert(21%) sepia(94%) saturate(2611%) hue-rotate(350deg) brightness(92%) contrast(97%);
        }
			}

			a.mat-button {
				width: 100%;
				text-align: left;
				font-weight: normal;
			}
		}

		mat-nav-list {
			border-top: 1px solid $border-color;

			a.mat-list-item {
				border-left: 3px solid transparent;
				box-sizing: border-box;
			}

			a.mat-list-item:hover,
			a.mat-list-item.active {
				color: $primary-color;
				border-left: 3px solid $primary-color;
				box-sizing: border-box;
			}
		}
	}

	mat-toolbar {
		.app-logo-container {
			border-right: 1px solid $border-color;
		}
		.theme-switcher-container {
			color: $subtitle-color;
		}
	}

	.create-new-button {
		border-radius: 20px;
		color: white;
		background-color: $primary-color;
	}

	.secondary {
		margin-left: 20px;
		width: 250px;
		border-radius: 20px;
		color: white;
		background-color: $border-color;
	}

	th.mat-header-cell:last-of-type,
	td.mat-cell:last-of-type,
	td.mat-footer-cell:last-of-type {
		padding-right: 0px;
	}

	app-home {
		.quick-link-more {
			button {
				height: 12px;
				width: 12px;
				line-height: 12px;

				mat-icon {
					height: 12px;
					width: 12px;
					line-height: 12px;
					font-size: 12px;
				}
			}

			button:first-child {
				color: red;
			}
		}

		.home-container {
			background-color: $darker-bg-color;
			padding: 40px;

			.welcome-message {
				text-transform: uppercase;
			}

			.home-title {
				font-size: 2em;
				margin-bottom: 50px;
			}

			.home-section-container {
				margin-bottom: 50px;
			}

      .home-section-courses-container {
        margin-bottom: 50px;
      }

			.home-section {
				font-size: 1.5em;

				.red {
					color: $primary-color;
				}

				margin-bottom: 10px;
			}

			.stats-container {
				margin-top: 20px;
				margin-bottom: 20px;

				.info {
					height: 48px;
					border-radius: 20px;
					display: flex;
					margin-right: 20px;
					padding-left: 40px;
					width: 100%;
					align-items: center;

					span:first-child {
						font-size: 3em;
					}

					span:last-child {
						font-size: 1.5em;
					}
				}
			}

			.quick-link {
				cursor: pointer;
				border-radius: 20px;
				height: 180px;
				width: 100%;

				.black-layer {
					border-radius: 20px;
					background-color: rgba(0, 0, 0, 0.5);
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					box-sizing: border-box;

					color: white;
					display: flex;
					align-items: flex-end;
					text-transform: uppercase;
					text-decoration: none;

					.mat-icon {
						margin-right: 8px;
					}
				}
				.info {
					position: absolute;
					bottom: 0;
					left: 0;
					display: flex;
					align-items: center;
					padding: 5px;
					margin-bottom: 7px;

					.text {
						font-size: 13px;
						font-weight: bold;
						text-shadow: black 1px 0 10px;
            text-align: left;
					}

					.extra-inscyd-icon-moon-athletes {
						font-size: 30px;
						margin-right: 8px;
					}

					.extra-inscyd-icon-moon-tests {
						font-size: 27px;
						margin-right: 8px;
					}
				}
			}

			.recent-activity-container {
				margin-top: 40px;

				mat-card {
					margin-bottom: 20px;
					border-radius: 20px;
					display: flex;
					flex-direction: row;

					.icon {
						height: 80px;
						width: 80px;
						background: $border-color;
						border-radius: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 20px;

						mat-icon {
							transform: scale(2);
							color: $subtitle-color;
						}
					}

					.info {
						display: flex;
						flex-direction: column;
						justify-content: space-around;

						.category {
							font-size: 0.8em;
							text-transform: uppercase;
							color: $subtitle-color;
						}

						.title {
							font-weight: bold;
							color: $text-color;
						}

						.date {
							font-size: 0.8em;
							color: $subtitle-color;
						}
					}

					.action {
						margin-left: auto;
						display: flex;
						align-items: center;

						a {
							background-color: black;
							color: white;
							width: 80px;
							border-radius: 20px;
						}
					}
				}
			}
		}
	}

	// APC
	app-athlete-performance-center,
	app-apc {
		.title {
			font-size: 1.2em;
		}

		.sub-title {
			font-size: 0.8em;
			font-weight: bold;
		}

		.result-container {
			display: flex;
			align-content: space-around;
			align-items: center;
			justify-content: space-between;
		}

		.mat-drawer-content.mat-sidenav-content.ng-star-inserted {
			// overflow-y: scroll;
			overflow: hidden !important;
		}

		mat-sidenav-container {
			height: 100%;

			mat-sidenav {
				width: 300px;
				// padding: 20px;
				border-left: 1px solid $border-color;
			}
		}

		mat-form-field {
			margin-top: 10px;
			width: 100%;
		}

		.athlete-info-container {
			display: flex;
			align-content: center;
			flex-direction: row;
			align-items: center;
			padding-top: 4px;
			padding-bottom: 4px;

			.athlete-image {
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;

				mat-icon {
					color: $subtitle-color;
					transform: scale(1.4);
				}
			}

			.athlete-info {
				margin-left: 10px;

				.athlete-name {
					font-size: 1em;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 200px;
					color: $text-color;
				}

				.team-name {
					font-size: 0.7em;
					color: $subtitle-color;
				}
			}
		}

		.apc-container::-webkit-scrollbar {
			display: none;
			/* Safari and Chrome browsers */
		}

		.apc-container {
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			/* mozilla */

			// padding: 40px;
			padding: 40px 80px 40px 40px;
			overflow: scroll;
			height: 100%;
			box-sizing: border-box;

			.sub-title {
				font-size: 0.7em;
				font-weight: bold;
			}

			.title {
				font-size: 2.5em;
				line-height: 1.5em;
				margin-bottom: 20px;
			}

			.section-title {
				margin-top: 30px;
				font-size: 1.1em;
			}

			.section {
				@include section($darker-bg-color, $shadow-color, $border-color, $subtitle-color);
			}

			.section-transparent {
				margin-top: 10px;
				box-sizing: border-box;
				width: 100%;
				margin-bottom: 30px;
			}

			mat-accordion.apc-comment {
				mat-expansion-panel {
					background: $light-bg-color;

					mat-expansion-panel-header {
						padding: 0 12px 0 12px;
						height: 32px;
					}

					div.mat-expansion-panel-body {
						padding: 2px 0 0 0;
					}

					textarea {
						background: $lighter-bg-color;
						padding: 12px;
						border-radius: 4px;
						box-sizing: border-box;
						border-top: 1px solid $dark-bg-color;
					}
				}
			}
		}
	}

	// SHARED
	.tag-inline {
		display: inline-block;
		border: 1px solid $border-color;
		border-radius: 8px;
		background: $darker-bg-color;
		padding: 2px 8px 2px 8px;
		margin: 3px 4px 3px 0;
	}

	.mat-dialog-container {
		max-height: inherit;
	}

	.group-actions {
		text-align: right;

		> *:not(:last-child) {
			margin-right: 10px;
		}
	}

	.page-container {
		padding: 40px;
		box-sizing: border-box;

		.sub-title {
			font-size: 0.7em;
			font-weight: bold;
		}

		.title {
			font-size: 2.5em;
			line-height: 1.5em;
		}

    .margin-bottom-20 {
      margin-bottom: 20px;
    }

		.title-with-search {
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			p {
				font-size: 2.5em;
				line-height: 1.5em;
			}
		}
	}

	@media (min-width: 1200px) {
		.mat-dialog-container {
			width: 2000px !important;
		}
	}

	@media (max-width: 1200px) {
		.swiper-slide img {
			object-fit: cover !important;
			object-position: center 0;
		}
	}

	.training-zones {
		box-sizing: border-box;
		overflow-x: auto;
		table {
			border-collapse: collapse !important;
			background-color: $lighter-bg-color;
			width: 100%;

			td,
			th {
				border: 1px solid $border-color;
				text-align: center;
				padding: 10px;
			}

			th {
				color: white;
				background-color: black;
			}

			.title-zone {
				color: $subtitle-color;
				font-size: 12px;
			}
		}
	}

	.training-zones::-webkit-scrollbar {
		width: 2px;
		height: 3px;
	}

	.training-zones::-webkit-scrollbar-track {
		box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}

	.training-zones::-webkit-scrollbar-thumb {
		background-color: #cccccc;
		border: 1px solid #555555;
	}

	.table {
		width: 100%;
		border-collapse: collapse;

		thead {
			border-radius: 5px;
			tr {
				th {
					padding: 10px 25px;
					border: 0;
					border-right: 1px solid $border-color;

					&:last-child {
						border-right: 0;
					}
				}
			}
		}

		td,
		th {
			vertical-align: middle;
		}

		tr {
			border: 1px solid $border-color;
			td {
				padding: 10px 25px;
				border: 1px solid $border-color;
			}
		}

		td {
			padding: 5px 10px;

			&.vertical-top {
				vertical-align: top;
			}

			.collapse-button {
				cursor: pointer;
				vertical-align: middle;
				display: inline-block;
			}
		}

		tbody {
			tr.disabled {
				opacity: 0.7;
			}
		}

		.status {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: inline-block;
		}

		.align-center {
			.mat-sort-header-container {
				justify-content: center;
			}
		}

		.no-data {
			td {
				padding: 10px;
				background: $shadow-color;
			}
		}
	}

	section.content {
		margin: 0 0 0 300px;
	}

	section.content {
		margin-left: 0 !important;
	}

	.container-fluid {
		padding: 0 !important;
	}

	.mat-form-field-infix {
		width: auto !important;
		display: flex;
	}

	button {
		&:disabled {
			border-color: $subtitle-color !important;
			cursor: not-allowed !important;

			&:hover {
				background: $subtitle-color !important;
			}
		}
	}

	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 575.98px) {
		.mat-dialog-container {
			width: 500px !important;
		}

		.auth-container {
			position: relative !important;
			padding: 20px 0;

			.login-box {
				width: 90%;

				.login-button,
				.create-button {
					color: #ffffff !important;
				}
			}
		}
	}

	.mat-paginator-container {
		width: auto !important;
	}

	.mat-paginator {
		background-color: transparent !important;
	}

	.swiper {
		width: 100%;
		height: 100%;
		padding-bottom: 20px;

    .swiper-button-next,
    .swiper-button-prev {
      color: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #f44336;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: initial;
    }

		.swiper-pagination {
			text-align: center;
			bottom: 0;

			.swiper-pagination-bullet-active {
				background-color: $primary-color !important;
			}

			.swiper-pagination-bullet {
				background-color: $subtitle-color;
			}
		}
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: $darker-bg-color;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		border-radius: 20px;
	}

  .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center 0;
		border-radius: 20px;
    opacity: 0.75;
	}

  .swiper-slide img:hover {
    opacity: 1;
  }

	.mat-pseudo-checkbox-checked {
		background: red !important;
	}
	.apc-report-dialog {
		.mat-dialog-content {
			display: flex;
			flex-direction: column;
		}
	}

	#surfaceContainer path {
		stroke: white;
		stroke-opacity: 0.2;
	}

	#surfaceContainer .edge path {
		stroke: white;
		stroke-width: 2px;
		stroke-opacity: 0.6;
		fill: none;
	}

	#surfaceContainer {
		.dot {
			circle {
				stroke: #444;
				stroke-width: 1;
				fill: #000;
				cursor: help;
			}
		}

		.asix {
			circle {
				stroke: #444;
				stroke-width: 1;
				fill: #e0e0df;
				cursor: help;
			}

			text {
				stroke: none;
				stroke-width: 1;
				fill: $text-color;
				cursor: help;
			}
		}
	}

	#accumulation_recovery_selection {
		.title,
		.label {
			fill: $text-color;
		}

		.axis-value {
			text {
				fill: $subtitle-color;
			}
		}
	}

	#time-to-depletion {
		.mat-form-field-infix {
			padding: 0px;
			padding-bottom: 5px;
			padding-top: 8px;
		}

		mat-form-field {
			input {
				font-size: 13px;
			}

			.mat-form-field-label {
				mat-label {
					font-size: 13px;
				}
			}
		}
	}

	.zone-fieldset .close-button mat-icon {
		height: 20px;
		width: 20px;
		line-height: 20px;
		font-size: 15px;
	}

	.btn-icon-text {
		margin-right: 10px;

		mat-icon {
			font-size: 16px;
			line-height: 17px;
			height: 17px;
			width: 17px;
			margin-left: -5px;
		}
	}

	#recovery-matrix {
		.left-chart {
			#point_selection_table {
				.mat-form-field-appearance-outline {
					.mat-form-field-infix {
						padding: 0px;
						padding-bottom: 5px;
						padding-top: 8px;
					}
				}

				mat-form-field {
					input {
						font-size: 13px;
					}

					.mat-form-field-label {
						mat-label {
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	.water-mark-wrapper {
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	#metabolic_fingerprint {
		.water-mark {
			position: absolute;
			background-size: contain;
			padding: 10px;
			width: 50px;
			height: 50px;
			top: 46%;
			left: 49%;
			margin: -25px 0 0 -25px;
		}
	}

	.comment-format-warpper {
		position: relative;

		.btn-readmore-comment {
			color: $text-color;
			cursor: pointer;
			&:hover {
				.popover-comment {
					display: inline;
				}
			}
		}

		.popover-comment {
			width: 180px;
			position: absolute;
			left: 30px;
			top: 20px;
			background-color: #626262;
			border-radius: 5px;
			padding: 7px;
			z-index: 9;
			display: none;
			word-break: break-word;
			white-space: initial;
			color: white;
		}
	}

	#metabolic_profile {
		&.hide-aerobic {
			.bk-grid-row:nth-child(3) {
				> div {
					&:nth-child(1),
					&:nth-child(3) {
						visibility: hidden;
					}
				}
			}
		}

		&.hide-fat {
			.bk-grid-row:nth-child(3) {
				> div {
					&:nth-child(2),
					&:nth-child(4) {
						visibility: hidden;
					}
				}
			}
		}
	}

	.title-with-search mat-icon {
		cursor: pointer;
	}

	.mat-cell,
	.mat-header-cell {
		padding: 5px;
	}

	.fixed-width-action {
		min-width: 200px;
	}

	.fixed-width-action-large {
		min-width: 280px;
	}

	.hide-long-text {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.mat-table {
		tr:not(.no-data-row):not(.mat-header-row):hover {
			background-color: $border-color;
		}

		.no-data-row {
			text-align: center !important;

			td {
				padding-right: 24px !important;
			}
		}
	}

	.primary-color {
		color: $primary-color;
	}
	.border-color {
		border-color: $border-color;
	}
	.shadow-bg {
		background-color: $shadow-color;
	}

	.success-bg {
		background-color: $success-color;
	}

	.icon-moon-primary {
		> span {
			&::before {
				color: $primary-icon;
			}
		}
		&.active {
			> span {
				&::before {
					color: $primary-color;
				}
			}
		}
	}

	.full-width {
		width: 100%;
	}

	fieldset {
		border: 1px solid $border-color;
		padding: 10px;
		position: relative;
		border-radius: 10px;
		margin-top: 10px;
	}

	.mat-sort-header-content {
		sub {
			margin-top: 5px;
		}
	}

	.mat-button-toggle-group {
		.mat-button-toggle-checked {
			background: #e6261c;
			color: white;
		}
		.mat-button-toggle-appearance-standard {
			.mat-button-toggle-label-content {
				line-height: 36px;
			}
		}
	}
	.dialog-add-detail-form {
		fieldSet {
			border-radius: 4px;
			margin-bottom: 32px;
			padding: 12px;
			display: flex;
			flex-wrap: wrap;
			.field {
				flex: 47%;
				margin: 0 8px;
			}
			.action {
				text-align: end;
			}
			.photo {
				padding: 12px;
				display: flex;
				flex-direction: column;
				align-items: center;
				img {
					max-width: 500px;
					height: auto;
					margin-bottom: 8px;
					min-width: 100px;
				}
			}
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: $text-color;
		transition: background-color 5000s ease-in-out 0s;
	}

	// custom material form field

	// form field highlight color
	.mat-form-field-appearance-outline {
		&.mat-focused {
			.mat-form-field-outline-thick {
				color: #2196f3;
			}
		}
	}
	.mat-form-field.mat-focused .mat-form-field-label {
		color: #2196f3;
	}

	.base-dialog {
		.header {
			display: flex;
			justify-content: space-between;
			mat-icon {
				cursor: pointer;
			}
		}
	}

	.iatac-icon-icons8-microsoft-excel {
		font-size: 25px;
	}

	.table-responsive {
		overflow-x: auto;
		box-sizing: border-box;
	}

	.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
		width: auto !important;

		.mat-option {
			height: 40px;
			line-height: 40px;
			font-size: 15px;
		}
	}

	#metabolic_capacities_gauge .item-title {
		a {
			position: relative;
			z-index: 1;
			color: $text-color;

			&:hover {
				color: $primary-color;
			}
		}
	}

	.chips-error {
		padding: 0 1em;
		font-size: 75%;
		margin-top: -19px;
	}

	.mat-tooltip {
		background-color: #f44336;
	}

	.link {
		color: #007aff;
		cursor: pointer;
	}
}

@mixin section($darker-bg-color, $shadow-color, $border-color, $subtitle-color) {
	margin-top: 10px;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 20px;
	background: $darker-bg-color;
	min-height: 100px;
	box-shadow: 0 0 20px $shadow-color;
	-moz-box-shadow: 0 0 20px $shadow-color;
	-webkit-box-shadow: 0 0 20px $shadow-color;
	-o-box-shadow: 0 0 20px $shadow-color;

	.measured_values_table {
		border-collapse: collapse;
		width: 100%;

		th,
		td {
			text-align: center;
			height: 2em;
			border: 1px solid $border-color;
		}

		thead {
			box-shadow: none;
		}
	}

	.weighted_values_table {
		border-collapse: collapse;
		width: 100%;

		td {
			width: 33%;
			text-align: center;
			//background-color: #303030;
		}
		th {
			text-align: center;
			border: 1px solid $border-color;
			padding: 5px;
		}

		thead {
			box-shadow: none;
		}
	}

	.athlete-data-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;

		.image {
			height: 100px;
			width: 100px;
			display: flex;
			align-items: center;
			justify-content: center;

			mat-icon {
				transform: scale(3);
				color: $subtitle-color;
			}

			.photo {
				max-height: 100px;
				max-width: 100px;
				border-radius: 50%;
			}
		}

		.info {
			flex: 1;
			padding-left: 20px;

			.big-name {
				font-size: 2em;
			}

			.test-col-name {
				margin-top: 15px;
				font-size: 1em;
				color: $subtitle-color;
			}

			.test-col-val {
				font-size: 0.9em;
			}
		}

		.sponsor {
			display: flex;
			width: 160px;
			justify-content: center;
		}
	}

	.body-composition-container {
		display: flex;
		flex-direction: row;

		.table {
			margin: 10px;
		}
	}
}
