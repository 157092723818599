/*------------------------------------*\
  #Colors
\*------------------------------------*/
@use "../variables";

.u-color-red {
  color: variables.$red;
}

.u-color-red-faded {
  color: variables.$faded-red;
}

.u-color-red-light {
  color: variables.$red-light;
}

.u-color-grey-light {
  color: variables.$grey-light;
}

.u-color-gray {
  color: variables.$dark-gray-color;
}

.u-color-white {
  color: white !important;
}


.u-color-black {
  color: variables.$login-background-color !important;
}

.u-color-grey-light {
  color: variables.$grey-light !important;
}

.u-color-blue {
  color: variables.$blue;
}

.u-color-black-light {
  color: variables.$black-light;
}
