/*------------------------------------*\
  #Background colors
\*------------------------------------*/
@use "variables";

.u-background-light-blue {
  background-color: variables.$blue;
}

.u-background-dark-blue {
  background-color: variables.$dark-blue;
}

.u-background-white {
  background-color: white;
}


.u-background-faded-red {
  background-color: variables.$faded-red;
}
