/*------------------------------------*\
  #Font sizes
\*------------------------------------*/
@use "../variables";

.general-info--font-size-35 {
  font-size: variables.$font-size-35;
}

.u-font-size-normal {
  font-size: variables.$global-size-m;
}

.u-font-size-16 {
  font-size: variables.$global-size;
}

.u-font-size-12 {
  font-size: variables.$global-size-12;
}

.u-font-size-14 {
  font-size: variables.$global-size-14;
}

.u-font-size-20 {
  font-size: variables.$global-size-m-m;
}

.u-font-size-24 {
  font-size: variables.$global-size-24;
}

.u-font-size-small {
  font-size: variables.$global-size-s;
}

.u-font-size-xsmall {
  font-size: variables.$font-size-10;
}

.u-font-size-28 {
  font-size: variables.$font-size-28;
}

.u-font-size-60 {
  font-size: variables.$font-size-60;
}
