/*------------------------------------*\
  #Angular text editor
\*------------------------------------*/

@use "variables";

.dark-theme .angular-editor-textarea {
  color: variables.$gray-border-color;
}

.light-theme .angular-editor-textarea {
  color: variables.$login-background-color;
}

.angular-editor-textarea {
  resize: none !important;
  padding: 0!important;

  &:after {
    display: none !important;
  }
}
