/*------------------------------------*\
  #Chart gauge
\*------------------------------------*/
@use "variables";

.c-chart-gauge__item {
  text-align: center;
}

.c-chart-gauge__title {
  min-height: 40px;

  @media print {
    a {
      text-decoration: none !important;
      color: variables.$gray-color;
    }
  }
}
