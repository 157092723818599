@font-face {
  font-family: 'inscyd-icons-moon';
  src:  url('fonts/icomoon.eot?y2lj0v');
  src:  url('fonts/icomoon.eot?y2lj0v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y2lj0v') format('truetype'),
    url('fonts/icomoon.woff?y2lj0v') format('woff'),
    url('fonts/icomoon.svg?y2lj0v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iatac-icon-"], [class*=" iatac-icon-"] {
  font-family: 'inscyd-icons-moon',sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.iatac-icon-participation:before {
  content: "\e90a";
}
.iatac-icon-teams:before {
  content: "\e90b";
}
.iatac-icon-user-group-man-man-filled:before {
  content: "\e909";
}
.iatac-icon-economy:before {
  content: "\e902";
}
.iatac-icon-load-analysis:before {
  content: "\e903";
}
.iatac-icon-manage:before {
  content: "\e904";
}
.iatac-icon-competition:before {
  content: "\e905";
}
.iatac-icon-recover-matrix:before {
  content: "\e906";
}

.iatac-icon-lactate-profile:before {
  content: "\e906";
}

.iatac-icon-teams-competitions:before {
  content: "\e907";
}
.iatac-icon-time:before {
  content: "\e908";
}
.iatac-icon-training-zone:before {
  content: "\e900";
}
.iatac-icon-test-data:before {
  content: "\e901";
}
.iatac-icon-icons8-microsoft-excel .path1:before {
  content: "\e90c";
  color: rgb(76, 175, 80);
}
.iatac-icon-icons8-microsoft-excel .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.iatac-icon-icons8-microsoft-excel .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(46, 125, 50);
}
.iatac-icon-icons8-microsoft-excel .path4:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
