@import "variables";

.o-flex {
  display: flex;
}

.o-flex--column {
  flex-direction: column;
}

.o-flex--wrap {
  flex-wrap: wrap;
}

.o-flex--justify-center {
  justify-content: center;
}

.o-flex--justify-left {
  justify-content: flex-start;
}

.o-flex--justify-right {
  justify-content: flex-end;
}

.o-flex--justify-space-around {
  justify-content: space-around;
}

.o-flex--justify-space-between {
  justify-content: space-between;
}

.o-flex--middle {
  align-items: flex-start;
}

.o-flex--justify-flex-start {
  align-items: flex-start;
}

.o-flex--justify-flex-end {
  align-items: flex-end;
}

.o-flex--align-items-center {
  align-items: center;
}

.o-flex--full {
  flex: 1;
}

.o-flex--gap-1 {
  gap: 1%;
}

.o-flex--gap-xxs {
  gap: $global-size-xxs;
}

.o-flex--gap {
  gap: $global-size;
}

.o-flex--self-start {
  align-self: start;
}
