/*------------------------------------*\
  #Wrapper
\*------------------------------------*/
.o-wrapper {
  margin-inline: auto;
  width: 860px;
  max-width: 100%;
}

.o-wrapper--tiny {
  width: 350px;
}
