/*------------------------------------*\
  #Opacity
\*------------------------------------*/

.u-opacity-1 {
  opacity: 1!important;
}

.u-opacity-point-3 {
  opacity: .3;
}

.u-opacity-point-8 {
  opacity: .8;
}
