/*------------------------------------*\
  #Spacings
\*------------------------------------*/

@use "variables";

$spacing-sizes: (
  null: variables.$global-size,
  "-mxxs": variables.$global-size-mxxs,
  "-xxs": variables.$global-size-xxs,
  "-xs": variables.$global-size-xs,
  "-s": variables.$global-size-s,
  "-m": variables.$global-size-m,
  "-l": variables.$global-size-l,
  "-xl": variables.$global-size-xl,
  "-xxl": variables.$global-size-xxl,
  "-xxxl": variables.$global-size-xxxl,
  "-none": 0,
  "-auto": auto
) !default;

$spacing-properties: (
  "padding": "padding",
  "margin": "margin",
) !default;

$spacing-directions: (
  null: null,
  "-top": "-top",
  "-right": "-right",
  "-bottom": "-bottom",
  "-left": "-left",
  "-horizontal": "-left" "-right",
  "-vertical": "-top" "-bottom",
) !default;

@each $property-namespace, $property in $spacing-properties {
  @each $direction-namespace, $direction-rules in $spacing-directions {
    @each $size-namespace, $size in $spacing-sizes {
      .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {
        @each $direction in $direction-rules {
          #{$property}#{$direction}: $size !important;
        }
      }
    }
  }
}


/**
 * Much bigger custom spacings
 * margins
 */
.u-margin-horizontal-80 {
  margin: 0 80px !important;
}
