@use "variables";

.u-padding-top-bottom-8 {
  padding-top: variables.$padding-8
}

.u-padding-bottom-15 {
  padding-bottom: variables.$padding-15
}

.u-right-padding-5 {
  padding-right: 5px;
}

.u-padding-30 {
  padding: 30px;
}

.u-padding-20 {
  padding: 20px;
}

.u-padding-16 {
  padding: 16px;
}

.u-padding-10 {
  padding: 10px;
}

.u-padding-left-20 {
  padding-left: variables.$padding-left-20;
}

.u-padding-left-right-5 {
  padding: 0 5px;
}
