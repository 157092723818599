/*------------------------------------------*\
  #Basic styles for page elements html, body
\*------------------------------------------*/

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #626262;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #222222;
}
