@font-face {
  font-family: "extra-inscyd-icon-moon";
  src: url('fonts/icomoon2.eot?qgbjp5');
  src: url('fonts/icomoon2.eot?qgbjp5#iefix') format("embedded-opentype"),
       url('fonts/icomoon2.ttf?qgbjp5') format("truetype"),
       url('fonts/icomoon2.woff?qgbjp5') format("woff"),
       url('fonts/extra-inscyd-icon-moon.svg?qgbjp5#icomoon') format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

[class^="extra-inscyd-icon-moon-"], [class*=" extra-inscyd-icon-moon-"] {
  font-family: 'extra-inscyd-icon-moon',sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.extra-inscyd-icon-moon-tests .path1:before {
  content: "\e909";
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path5:before {
  content: "\e90d";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path6:before {
  content: "\e90e";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path7:before {
  content: "\e90f";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path8:before {
  content: "\e910";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path9:before {
  content: "\e911";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path10:before {
  content: "\e912";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path11:before {
  content: "\e913";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-tests .path12:before {
  content: "\e914";
  margin-left: -1em;
  color: #fff;
}

.extra-inscyd-icon-moon-athletes .path1:before {
  content: "\e901";
  color: #fff;
}
.extra-inscyd-icon-moon-athletes .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-athletes .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: #fff;
}
.extra-inscyd-icon-moon-athletes .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: #fff;
}

.extra-inscyd-icon-moon-single:before {
  content: "\e915";
}

.extra-inscyd-icon-moon-multi:before {
  content: "\e916";
}
