/**
 * Global colors
 */
$dark-gray-color:               #8e8e8e;
$darker-gray-color:             #626262;
$grey-light-2:                  #424242;
$grey-text:                     #4D4D4D;
$login-background-color:        #101c1e;
$black:                         #000000;
$black-light:                   #303030;
$blue:                          #1cace5;
$light-blue:                    #1CACE50D;
$fade-blue:                     #1CACE51A;
$dark-blue:                     #16282b;
$gray-color:                    #3c3c3d;
$blue-color:                    #000080;
$gray-border-color:             #dddddd;
$light-gray-color:              #fbfbfb;
$white:                         #ffffff;
$red-color:                     #dd4b39;
$red:                           #ff0000;
$faded-red:                     #DE4B39;
$red-light:                     #E6261C;
$dark-red:                      #b72237;
$yellow:                        #ffff00;
$orange:                        #ff8000;
$pink:                          #ffc0cb;
$green:                         #008000;
$dark-blue:                     #0000ff;
$light-green:                   #8ce8ab;
$purple:                        #e066ff;
$grey-light:                    #717171;

/**
 * Global sizes
 */
$global-size-mxxs:              2px;
$global-size-xxs:               4px;
$global-size-xs:                8px;
$global-size-s:                 12px;
$global-size-12:                12px;
$global-size-13:                13px;
$global-size-14:                14px;
$global-size-24:                24px;
$global-size:                   16px;
$global-size-m:                 18px;
$global-size-m-m:               20px;
$global-size-l:                 22px;
$global-size-xl:                32px;
$global-size-xxl:               38px;
$global-size-xxxl:              46px;

/**
 * Global border radius
 */
$border-radius-s:               3px;
$border-radius:                 6px;
$border-radius-l:               8px;
$border-radius-xl:              10px;

/**
 * Global font sizes
 */
$font-size-20:                  20px;
$font-size-28:                  28px;
$font-size-32:                  $global-size-xl;
$font-size-60:                  60px;
$font-size-35:                  35px;
$font-size-18:                  $global-size-m;
$font-size-16:                  16px;
$font-size-14:                  14px;
$font-size-12:                  12px;
$font-size-11:                  11px;
$font-size-10:                  10px;


/**
 * Global line height
 */
$line-height-28:                  28px;
$line-height-20:                  20px;
$line-height-18:                  18px;

/**
 * Global margin
 */
$margin-bottom-8:                   8px;
$margin-bottom-10:                  10px;
$margin-bottom-20:                  20px;
$margin-bottom-30:                  30px;
$margin-top-20:                     20px;
$margin-top-30:                     30px;
$margin-top-10:                     10px;
$margin-bottom-50:                  50px;
$margin-top-50:                     50px;
$margin-5:                          5px;
$margin-right-50:                   50px;
$margin-right-20:                   20px;
$margin-right-10:                   10px;
$margin-right-5:                    5px;
$margin-left-10:                    10px;

/**
 * Global paddings
 */
$padding-8:                         8px;
$padding-15:                        15px;
$padding-left-20:                   20px;
