/* You can add global styles to this file, and also import other style files */
@import "./styles/themes";
@import "./styles/index";
@import 'src/styles/variables';

:root {
  --reportGridPageHeight: 1400px;
  --reportGridPageWidth: 1080px
}

.mat-dialog-content {
  max-height: 70vh !important;
}

#metabolic_profile,
#metabolic_crossing {
  .bk-grid-column {
    height: auto !important;
  }

  .bk-grid-row {
    height: auto !important;

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .bokeh-row {
    margin: 0 20px;
  }

  .bokeh-legend {
    width: 50% !important;
  }
}

#performance_development {
  .bk-toolbar-wrapper {
    top: 0 !important;
  }
}

.bk-root {
  .bk-logo {
    display: none !important;
  }

  .bk-button-bar-list.bk-bs-dropdown {
    color: #000;
  }
}

.mat-chip-remove {
  border: none;
  background: none;
  padding: 0;

  .mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .mat-dialog-container {
    width: 700px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .mat-dialog-container {
    width: 650px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mat-dialog-container {
    width: 650px !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .mat-dialog-container {
    width: 650px !important;
  }
}

.flex-table {
  .table-header {
    box-shadow: 0px 0px 5px 2px #ececec;
    border-radius: 5px;

    .line {
      display: flex;

      .column {
        padding: 10px;
        font-weight: bold;
      }
    }
  }

  .table-body {
    .line {
      display: flex;

      &:nth-last-of-type(even) {
        background: rgba(0, 0, 0, 0.05);
      }

      .column {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        &.align-right {
          justify-content: flex-end;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }
}

.table-toolbar {
  padding: 10px;
}

.sub-title-tab {
  padding: 10px;
}

.mat-simple-snackbar-action.text-right {
  text-align: right;
}

// .mat-drawer-content.mat-sidenav-content.ng-star-inserted {
//     overflow: hidden !important;
// }

.water-mark {
  background: url("assets/images/app-logo-header.png") no-repeat 50% 50%;
  opacity: 0.1;
  background-size: 200px 32px;
}

.light-theme {
  .water-mark {
    background: url("assets/images/app-logo-header.png") no-repeat 50% 50%;
  }

  .bk-tooltip {
    background-color: #ffffff !important;
  }
}

.dark-theme {
  .water-mark {
    background: url("assets/images/app-logo-header-dark-theme.png") no-repeat
      50% 50%;
  }

  .bk-tooltip {
    background-color: #222222 !important;
  }
}

.course-progress-bar {
  &.mat-progress-bar {
    height: 10px;
    border-radius: 14px!important;
  }

  .mat-progress-bar-fill::after {
    background-color: #e53227;
    border-radius: 10px!important;
  }

  .mat-progress-bar-buffer {
    background-color: #D8D8D8;
  }

  /* remove animation and the dots*/
  .mat-progress-bar-background {
    animation: none;
    background-color: #eceff1;
    fill: #eceff1;
  }
}

.active-status {
  color: $green!important;
  margin: 0!important;
}

.inactive-status {
  margin: 0!important;
  color: $red!important;
}

.margin-bottom-12 {
  margin-bottom: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}
