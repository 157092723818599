/*------------------------------------*\
  #Mat input styles
\*------------------------------------*/
.mat-input--flush {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
  }
}

.mat-input--white {
  .mat-input-element {
    color: white;
  }
}
