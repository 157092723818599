@use 'variables';

.line-height-normal {
  line-height: normal !important;
}


.line-height-28 {
  line-height: variables.$line-height-28;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-20 {
  line-height: variables.$line-height-20;
}

.line-height-18 {
  line-height: variables.$line-height-18;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-40 {
  line-height: 40px;
}
