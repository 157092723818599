/*------------------------------------*\
  #Overlay
\*------------------------------------*/
@use 'variables';

.u-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
}

.u-overlay-off {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  top: 0;
  z-index: 0;
}

