/*------------------------------------*\
  #Text align
\*------------------------------------*/

.u-text-align-center {
  text-align: center !important;
}

.u-text-align-right {
  text-align: right !important;
}

.u-text-align-left {
  text-align: left !important;
}
