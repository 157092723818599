/*------------------------------------*\
  #Border radius
\*------------------------------------*/
@use "variables";

/**
 * Border radius
 */
.u-border-radius-s {
  border-radius: variables.$border-radius-s;
}

.u-border-radius {
  border-radius: variables.$border-radius;
}

.u-border-radius-l {
  border-radius: variables.$border-radius-l;
}

/**
 * Borders
 */
.u-border-none {
  border: none !important;
}

.u-border {
  border: 1px solid #dddddd;
}

.u-border-color {
  border: 1px solid variables.$dark-gray-color!important;
}
