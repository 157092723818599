.u-capitalize {
  text-transform: capitalize
}

.u-text-underline {
  text-decoration: underline;
}

.u-text-uppercase {
  text-transform: uppercase;
}
