/*------------------------------------*\
  #Tables
\*------------------------------------*/
@use "variables";

.c-table {
  border-collapse: collapse;
  width: 100%;

  &,
  td,
  th {
    border: 1px solid variables.$gray-border-color;
  }

  td,
  th {
    padding: 4px;
  }
}
